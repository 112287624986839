import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"
import Slider from "react-slick"
import { Parallax } from "react-scroll-parallax"
import { Link } from "gatsby"
import VideoBox from "../components/AboutUs/videoBox"

function SectionNoSurrenderStudio() {
  return (
    <section className="pt-120 pb-120 position-relative overflow-hidden">
      <Parallax className="about-obj-1" x={[-20, 20]}>
        <div
          data-paroller-factor="-0.08"
          data-paroller-type="foreground"
          data-paroller-direction="horizontal"
        >
          <img src="/elements/about-obj-1.png" alt="no surrender" />
        </div>
      </Parallax>
      <Parallax className="about-obj-2" x={[20, -20]}>
        <div
          data-paroller-factor="0.08"
          data-paroller-type="foreground"
          data-paroller-direction="horizontal"
        >
          <img src="/elements/about-obj-2.png" alt="no surrender" />
        </div>
      </Parallax>

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="about-content">
              <div className="section-header has--bg">
                <div className="header-image style--two">
                  <img src="/elements/header-el-2.png" alt="no surrender" />
                </div>
                <h2 className="section-title">
                  NoSurrender <br /> Game Studio
                </h2>
              </div>
              <p>
                Our main focus is to provide the best entertainment possible for
                our players. We place key emphasis on creating games that are of
                a high quality in every way possible. This means that we ensure
                that all our games themes are engaging, our features unique and
                captivating and our art, stunning and precise! We don’t cut
                corners and do whatever is necessary to offer a memorable
                gameplay experience.
              </p>
              {/* <a className="cmn-btn mt-5" href="about.html">
            learn more
          </a> */}
              <Link className="cmn-btn  mt-5" to="/coming-soon">
                learn more
              </Link>
            </div>
          </div>
          <div className="col-lg-4 mt-lg-0 mt-4">
            <div className="about-thumb">
              <img
                className="image-1"
                src="/elements/about-player.png"
                alt="no surrender"
              />
              <img
                className="image-2"
                src="/elements/about-phone.png"
                alt="no surrender"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SampleNextArrow(props) {
  const { className, onClick, blogClass } = props
  return (
    // <div
    //     className={className}
    //     style={{ ...style, display: "block", background: "red" }}
    //     onClick={onClick}
    // />
    <div className={blogClass}>
      <div className={className + " next"} onClick={onClick}>
        <img src="/elements/next-btn.png" />
      </div>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick, blogClass } = props
  return (
    // <div
    //     className={className}
    //     style={{ ...style, display: "block", background: "green" }}
    //     onClick={onClick}
    // />
    <div className={blogClass}>
      <div className={className + " prev"} onClick={onClick}>
        <img src="/elements/prev-btn.png" alt="no surrender" />
      </div>
    </div>
  )
}

function SectionRoadMap() {
  return (
    <section className="pt-120 pb-120 position-relative overflow-hidden roadmap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-header text-center has--bg">
              <div className="header-image">
                <img src="/elements/header-el-2.png" alt="no surrender" />
              </div>
              <h2 className="section-title">ROADMAP</h2>
              <p>
                The table below shows the Roadmap for NoSurrender Game Studio.
                This is a rough guide to what features and progresses we hope to
                make over the coming year, but the dates and contents are
                subject to change as we advance.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-sm table-dark">
              <thead>
                <tr>
                  <th scope="col">Q1</th>
                  <th scope="col">Q2</th>
                  <th scope="col">Q3</th>
                  <th scope="col">Q4</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="released">Released</td>
                  <td className="inDev">In Development</td>
                  <td className="notReleased">Not Released</td>
                  <td className="notReleased">Not Released</td>
                </tr>
                <tr>
                  <td>v2.3.2</td>
                  <td>v2.3.3</td>
                  <td>vTBD</td>
                  <td>vTBD</td>
                </tr>
                <tr>
                  <td>
                    <p>Curve Library</p>
                    <p>9 Slice</p>
                    <p>Reduce Installer Size</p>
                    <p>Compiler Speed Ups</p>
                    <p>Console Updates</p>
                  </td>
                  <td>
                    <p>Asset Inspector 1</p>
                    <p>Replace OpenTK</p>
                    <p>Consolidation</p>
                    <p>Refactoring</p>
                    <p>Console Updates</p>
                    <p>Google Mobile Ads ext</p>
                    <p>Facebook ext</p>
                  </td>
                  <td>
                    <p>Asset Inspector 1</p>
                    <p>Replace OpenTK</p>
                    <p>Consolidation</p>
                    <p>Refactoring</p>
                    <p>Console Updates</p>
                    <p>Google Mobile Ads ext</p>
                    <p>Facebook ext</p>
                  </td>
                  <td>
                    <p>Asset Inspector 1</p>
                    <p>Replace OpenTK</p>
                    <p>Consolidation</p>
                    <p>Refactoring</p>
                    <p>Console Updates</p>
                    <p>Google Mobile Ads ext</p>
                    <p>Facebook ext</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>IDE Bugfixes</p>
                    <p>Runtime Bugfixes</p>
                  </td>
                  <td>
                    <p>IDE Bugfixes</p>
                    <p>Runtime Bugfixes</p>
                  </td>
                  <td>
                    <p>IDE Bugfixes</p>
                    <p>Runtime Bugfixes</p>
                  </td>
                  <td>
                    <p>IDE Bugfixes</p>
                    <p>Runtime Bugfixes</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionRecentPosts() {
  const blogSliderSettings = {
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    speed: 700,
    arrows: true,
    dots: false,
    nextArrow: <SampleNextArrow blogClass={"blog-slider"} />,
    prevArrow: <SamplePrevArrow blogClass={"blog-slider"} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className="pt-120 pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="section-header has--bg style--two">
              <div className="header-image style--two">
                <img src="/elements/header-el-4.png" alt="no surrender" />
              </div>
              <span className="section-sub-title">RECENT POSTS</span>
              <h2 className="section-title">Game News</h2>
            </div>
            {/* <a className="cmn-btn" href="coming-soon.html">
                View All posts
              </a> */}
            <Link className="cmn-btn" to="/coming-soon">
              View All posts
            </Link>
          </div>
          <div className="col-lg-8 mt-lg-0 mt-5">
            <Slider {...blogSliderSettings}>
              <div className="blog-slider">
                <div className="post-card">
                  <div className="post-card__thumb">
                    <img src="/blog/2.jpg" alt="no surrender" />
                  </div>
                  <div className="post-card__content">
                    <a className="post-type" href="coming-soon.html">
                      <i className="far fa-file-alt" />
                    </a>
                    <span className="date">Sep 15, 2020</span>
                    <h3 className="post-title">
                      <a href="coming-soon.html">
                        Benefits of Gaming - PC and Video Games
                      </a>
                    </h3>
                    <div className="post-author mt-3">
                      <div className="post-author__thumb">
                        <img src="/blog/author.png" alt="no surrender" />
                      </div>
                      <a className="post-author__name" href="coming-soon.html">
                        Andres Todd
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-slider">
                <div className="post-card">
                  <div className="post-card__thumb">
                    <img src="/blog/2.jpg" alt="no surrender" />
                  </div>
                  <div className="post-card__content">
                    <a className="post-type" href="coming-soon.html">
                      <i className="far fa-file-alt" />
                    </a>
                    <span className="date">Sep 15, 2020</span>
                    <h3 className="post-title">
                      <a href="coming-soon.html">
                        Benefits of Gaming - PC and Video Games
                      </a>
                    </h3>
                    <div className="post-author mt-3">
                      <div className="post-author__thumb">
                        <img src="/blog/author.png" alt="no surrender" />
                      </div>
                      <a className="post-author__name" href="coming-soon.html">
                        Andres Todd
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-slider">
                <div className="post-card">
                  <div className="post-card__thumb">
                    <img src="/blog/2.jpg" alt="no surrender" />
                  </div>
                  <div className="post-card__content">
                    <a className="post-type" href="coming-soon.html">
                      <i className="far fa-file-alt" />
                    </a>
                    <span className="date">Sep 15, 2020</span>
                    <h3 className="post-title">
                      <a href="coming-soon.html">
                        Benefits of Gaming - PC and Video Games
                      </a>
                    </h3>
                    <div className="post-author mt-3">
                      <div className="post-author__thumb">
                        <img src="/blog/author.png" alt="no surrender" />
                      </div>
                      <a className="post-author__name" href="coming-soon.html">
                        Andres Todd
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-slider">
                <div className="post-card">
                  <div className="post-card__thumb">
                    <img src="/blog/2.jpg" alt="no surrender" />
                  </div>
                  <div className="post-card__content">
                    <a className="post-type" href="coming-soon.html">
                      <i className="far fa-file-alt" />
                    </a>
                    <span className="date">Sep 15, 2020</span>
                    <h3 className="post-title">
                      <a href="coming-soon.html">
                        Benefits of Gaming - PC and Video Games
                      </a>
                    </h3>
                    <div className="post-author mt-3">
                      <div className="post-author__thumb">
                        <img src="/blog/author.png" alt="no surrender" />
                      </div>
                      <a className="post-author__name" href="coming-soon.html">
                        Andres Todd
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTrackRecord() {
  return (
    <section
      className="pt-120 pb-120 position-relative"
      style={{
        backgroundImage: 'url("/bg/overview.jpg")',
        backgroundSize: "cover",
      }}
    >
      {/* <div className="bg-el h-75">
        <img className="h-100" src="/bg/overview.jpg" alt="no surrender" />
      </div> */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="section-header text-center has--bg">
              <div className="header-image">
                <img src="/elements/header-el.png" alt="no surrender" />
              </div>
              <h2 className="section-title">Track Record</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-none-30">
          <div
            className="col-lg-4 col-md-6 mb-30 wow zoomIn"
            data-wow-duration="0.5s"
            data-wow-delay="0.3s"
          >
            <div className="overview-card">
              <div className="overview-card__icon">
                <img src="/icon/overview/1.png" alt="Games" />
              </div>
              <div className="overview-card__content">
                <h4 className="overview-card__number">+12,000</h4>
                <p>Games</p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 mb-30 wow zoomIn"
            data-wow-duration="0.5s"
            data-wow-delay="0.5s"
          >
            <div className="overview-card">
              <div className="overview-card__icon">
                <img src="/icon/overview/2.png" alt="players" />
              </div>
              <div className="overview-card__content">
                <h4 className="overview-card__number">+398943</h4>
                <p>players</p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 mb-30 wow zoomIn"
            data-wow-duration="0.5s"
            data-wow-delay="0.7s"
          >
            <div className="overview-card">
              <div className="overview-card__icon">
                <img src="/icon/overview/3.png" alt="champions" />
              </div>
              <div className="overview-card__content">
                <h4 className="overview-card__number">+103</h4>
                <p>champions</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                    <div className="col-lg-10">
                        <Slider {...testimonialSlider}>
                            <div className="testimonial-slider">
                                <div className="testimonial-single">
                                    <div className="testimonial-single__thumb" data-animation="fadeInUp" data-delay=".3s"><img src="/testimonial/1.png" alt="no surrender" /></div>
                                    <div className="testimonial-single__content" data-animation="fadeInUp" data-delay=".5s">
                                        <p>Vestibulum tincidunt tincidunt nisl id congue. Nam feugiat libero eu mattis bibendum. Maecenas luctus ante vel vestibulum ultrices.</p><span className="designation">Jonathan Davis</span>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-slider">
                                <div className="testimonial-single">
                                    <div className="testimonial-single__thumb" data-animation="fadeInUp" data-delay=".3s"><img src="/testimonial/1.png" alt="no surrender" /></div>
                                    <div className="testimonial-single__content" data-animation="fadeInUp" data-delay=".5s">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam feugiat libero eu mattis bibendum. Maecenas luctus ante vel vestibulum ultrices.</p><span className="designation">Iste magni</span>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-slider">
                                <div className="testimonial-single">
                                    <div className="testimonial-single__thumb" data-animation="fadeInUp" data-delay=".3s"><img src="/testimonial/1.png" alt="no surrender" /></div>
                                    <div className="testimonial-single__content" data-animation="fadeInUp" data-delay=".5s">
                                        <p>Et laborum porro, accusantium earum necessitatibus blanditiis ad incidunt nostrum, eum voluptas minus. Ad laudantium velit iure.</p><span className="designation">Vel accusamus</span>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div> */}
      </div>
    </section>
  )
}

function Games() {
  const gamesSliderSettings = {
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow blogClass={"games-slider"} />,
    prevArrow: <SamplePrevArrow blogClass={"games-slider"} />,
  }

  // const testimonialSlider = {
  //   autoplay: true,
  //   speed: 700,
  //   fade: true,
  //   arrows: true,
  //   dots: false,
  //   nextArrow: <SampleNextArrow blogClass={"testimonial-slider"} />,
  //   prevArrow: <SamplePrevArrow blogClass={"testimonial-slider"} />,
  // }

  return (
    <Layout>
      <Seo title="Games" />
      <InnerHero title={"Games"} />
      <section
        className="pt-120 pb-120 bg_img"
        style={{ backgroundImage: "url(/bg/featured.png)" }}
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="section-header style--two">
                <span className="section-sub-title">FEATURED GAME</span>
                <h2 className="section-title">NO Surrender HEROES</h2>
              </div>
              <p>
                Real-time strategy #P2E WEB3 game that allows players to do PVP Battles.
                Available on iOS & Android soon.
              </p>
              {/* <a className="cmn-btn style--two mt-5" href="coming-soon.html">
                learn more
              </a> */}
              <Link className="cmn-btn style--two mt-5 mb-5" to="/coming-soon">
                learn more
              </Link>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="featured-game-thumb text-center">
                <img src="/games/heroes_kv_square.webp" alt="no surrender" style={{ objectFit: "contain" }} />
                {/* <VideoBox holderstyle={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", marginTop: "0 !important" }}></VideoBox> */}
                <p className="mt-3 mb-3">Currently in Closed Beta</p>
                {/* <div className="d-flex flex-wrap justify-content-center">
        
                  <img
                    className="mr-3"
                    src="/elements/apple-btn.png"
                    alt="no surrender"
                  />
      
                  <img
                    className="ml-3"
                    src="/elements/google-btn.png"
                    alt="no surrender"
                  />
     
                </div> */}
              </div>
            </div>
          </div>
          <div className="row justify-content-between" style={{ marginTop: "36px" }}>
            <div className="col-lg-6">
              <div className="section-header style--two">
                {/* <span className="section-sub-title">FEATURED GAME</span> */}
                <h2 className="section-title">The War</h2>
              </div>
              <p>
                No Surrender The War is a static first person shooter featuring
                the best battle scenarios from WW2 era. Just relax sit back and
                enjoy shooting without a fuss.
              </p>
              {/* <a className="cmn-btn style--two mt-5" href="coming-soon.html">
                learn more
              </a> */}
              <Link className="cmn-btn style--two mt-5 mb-5" to="/coming-soon">
                learn more
              </Link>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="featured-game-thumb text-center">
                <img src="/elements/featured-game.png" alt="no surrender" />
                <VideoBox holderstyle={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", marginTop: "0 !important" }}></VideoBox>
                <p className="mt-3 mb-3">Download now at</p>
                <div className="d-flex flex-wrap justify-content-center">
                  {/* <a href="coming-soon.html"> */}
                  <img
                    className="mr-3"
                    src="/elements/apple-btn.png"
                    alt="no surrender"
                  />
                  {/* </a> */}
                  {/* <a href="coming-soon.html"> */}
                  <img
                    className="ml-3"
                    src="/elements/google-btn.png"
                    alt="no surrender"
                  />
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-120 pb-120">
        <div className="container">
          {/* <div className="games-slider"> */}
          <Slider {...gamesSliderSettings}>
            <div className="games-slider">
              <div className="game-slide">
                <div className="game-slide__image">
                  <img src="/games/slider-1.png" alt="no surrender" />
                </div>
                <div className="game-slide__slogan">
                  <h2>No Surrender…</h2>
                  <p>Creates most demanded concepts</p>
                </div>
              </div>
            </div>
            <div className="games-slider">
              <div className="game-slide">
                <div className="game-slide__image">
                  <img src="/games/slider-1.png" alt="no surrender" />
                </div>
                <div className="game-slide__slogan">
                  <h2>No Surrender…</h2>
                  <p>Makes the best UI design for the market</p>
                </div>
              </div>
            </div>
            <div className="games-slider">
              <div className="game-slide">
                <div className="game-slide__image">
                  <img src="/games/slider-1.png" alt="no surrender" />
                </div>
                <div className="game-slide__slogan">
                  <h2>No Surrender…</h2>
                  <p>Combines best systems from different genres</p>
                </div>
              </div>
            </div>
          </Slider>

          {/* </div> */}
        </div>
      </section>
      {/* <SectionNoSurrenderStudio /> */}
      {/* <SectionRoadMap /> */}
      {/* <SectionRecentPosts /> */}
      <SectionTrackRecord />
      <section className="pt-120 pb-120 position-relative">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="section-header style--two">
                <span className="section-sub-title">COMING SOON</span>
                <h2 className="section-title">NoSurrender: The Sniper</h2>
              </div>
              <p>A new breed of sniper Game</p>
              <Link className="cmn-btn style--two mt-5" to="/coming-soon">
                learn more
              </Link>
            </div>
            <div className="col-xl-4 col-lg-5 mt-4">
              <div className="featured-game-thumb text-center">
                <img src="/elements/featured-game.png" alt="no surrender" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Games
